<template>
  <div class="container">
    <div class="row">
      <div class="col mx-auto">
        <loading v-if="loading" />
        <div v-else v-html="invoice"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/views/components/loaders/Loading";
export default {
  components: {Loading},
  props: [],
  data() {
    return {
      loading: true,
      invoice: null,
    };
  },
  methods: {
    fetchOrderInvoice() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/client/orders/" + this.$route.params.id + "/invoice").then(({ data }) => {
        this.invoice = this.b64DecodeUnicode(data);
        this.loading = false;
      });
    },
    b64DecodeUnicode(str) {
      // Going backwards: from bytestream, to percent-encoding, to original string.
      return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
    }
  },
  mounted() {
    this.fetchOrderInvoice();
  },
};
</script>

<style>
</style>
